

import { Button } from './Button'
import { Container } from './Container'
import { TextField } from './Fields'
import { Logomark } from './Logo'
import { NavLinks } from './NavLinks'
import qrCode from '../images/qr-code.svg'

function QrCodeBorder(props) {
  return (
    <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
      <path
        d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export function Footer() {
  return (
      // <div></div>
    <footer className="border-t border-gray-200">
      <Container>
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <div className="flex items-center text-gray-900">
              <Logomark className="h-10 w-10 flex-none fill-cyan-500" />
              <div className="ml-4">
                <p className="text-base font-semibold">PocketCFO</p>
                <p className="mt-1 text-sm">Your finances, under control</p>
              </div>
            </div>
            <nav className="mt-11 flex gap-8">
              <NavLinks />
            </nav>
          </div>
            <div>
                <h3>Contact us</h3>
                <a href="mailto:hello@pocketcfo.tech" className="text-primary">hello@pocketcfo.tech</a>
            </div>
        </div>

      </Container>
    </footer>
  )
}
