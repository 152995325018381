import { Container } from './Container'

const faqs = [
  [
    {
      question: 'How is this different from traditional methods used by accountants?',
      answer:
        'Traditional accounting often involves waiting for year-end reports or manually compiling data. Our AI analyzes your daily bank transactions, providing real-time insights like sales trends and cost optimization opportunities. It’s like having a CFO constantly monitoring your finances, at a fraction of the cost',
    },
    {
      question: 'What kind of insights will I receive about my sales?',
      answer:
        'We go beyond basic figures. Our AI analyzes trends in your income streams, identifies seasonal fluctuations and helps you forecast future revenue. This empowers you to make informed decisions about pricing, marketing and resource allocation',
    },

  ],
  [
    {
      question: 'Do I still need an accountant?',
      answer:
          'Your Pocket CFO will give you actionable insights about your business on a regular basis, in simple, clear language. So that you can make informed decisions. In some cases, you may feel more comfortable to talk about these insights to your advisors or even give them access to your PocketCFO. They know your business, but often lack the time to inform you during the year. They will certainly continue to produce your annual report and/or tax filings',
    },
    {
      question: 'What do you mean by CO₂ impact of my business?',
      answer:
        'By analyzing your spending patterns, we can estimate the environmental impact associated with your vendors and suppliers. This empowers you to make informed choices towards a more sustainable business model',
    }
  ],
  [
    {
      question: 'Can your AI truly predict my bank balance 18 months in advance?',
      answer:
          'Our AI forecasts are based on your historical transaction data and industry trends. While not a guaranteed crystal ball, these predictions can be a powerful tool for financial planning. The further out the prediction, the higher the potential for variance, but it helps you identify potential shortfalls or surpluses in advance so that you can take action',
    },
    {
      question: 'Is my financial data secure with PocketCFO?',
      answer:
          'Absolutely. We employ industry-standard security protocols to safeguard your financial information. We only access data you authorize through secure connections via your bank, as dictated by <a href="https://www.rijksoverheid.nl/onderwerpen/financiele-sector/nieuwe-wetgeving-voor-betalingsverkeer-psd2" target="_blank" class="text-primary">PSD2 regulation</a>',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-t border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            If you have anything else you want to ask,{' '}
            <a
              href="mailto:info@example.com"
              className="text-gray-900 underline"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-gray-900" dangerouslySetInnerHTML={{__html: faq.question}}>
                    </h3>
                    <p className="mt-4 text-sm text-gray-700" dangerouslySetInnerHTML={{__html: faq.answer}}></p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
