'use client'

import { useState } from 'react'
import clsx from 'clsx'

import { Button } from './Button'
import { Container } from './Container'
import { Logomark } from './Logo'

const plans = [
  {
    name: 'Starter',
    featured: false,
    price: { Monthly: 'Free', Annually: '$0' },
    description:
      'First month free, on us! After the trial month, this plan converts to the Pro plan',
    button: {
      label: 'Coming Soon',
      href: '#',
    },
    features: [
      {
        name: 'Revenue Streams',
        desc: 'Shows where your revenue comes from'
      },
      {
        name: 'Expense Categorisation',
        desc: 'Shows where you spend your money'
      },
      {
        name: 'Top Customers',
        desc: 'Shows your biggest revenue generators'
      },
      {
        name: '3 Months out Cash Flow Projection',
        desc: 'Predicts your cash balance 3 months in the future'
      }
    ],
    logomarkClassName: 'fill-gray-300',
  },
  {
    name: 'Pro',
    featured: false,
    price: { Monthly: '14,95', Annually: '$70' },
    description:
      'Start proactively looking forward, and getting suggestions, using our Pro plan',
    button: {
      label: 'Coming Soon',
      href: '#',
    },
    features: [
      {
        name: 'Revenue Streams',
        desc: 'Shows where your revenue comes from'
      },
      {
        name: 'Expense Categorisation',
        desc: 'Shows where you spend your money'
      },
      {
        name: 'Top Customers',
        desc: 'Shows your biggest revenue generators'
      },
      {
        name: 'Customers Insights',
        desc: 'Get different insights about your customers'
      },
      {
        name: 'Expense trends',
        desc: 'Add insights to your expenses'
      },
      {
        name: 'Profitability',
        desc: 'Get insight into your profit'
      },
      {
        name: ' VAT and Income tax projections',
        desc: 'Predictions of what your tax will be'
      },
      {
        name: '12 Months out Cash Flow Projection',
        desc: 'Predicts your cash balance 12 months in the future'
      }
    ],
    logomarkClassName: 'fill-gray-500',
  },
  {
    name: 'Pro AI',
    featured: true,
    price: { Monthly: '54,95', Annually: '$1,990' },
    description:
      'You’ve got a huge amount of assets but it’s not enough. To the moon.',
    button: {
      label: 'Coming Soon',
      href: '#',
    },
    features: [
      {
        name: 'Revenue Streams',
        desc: 'Shows where your revenue comes from'
      },
      {
        name: 'Expense Categorisation',
        desc: 'Shows where you spend your money'
      },
      {
        name: 'Top Customers',
        desc: 'Shows your biggest revenue generators'
      },
      {
        name: 'Client Diversity',
        desc: ''
      },
      {
        name: 'Expense trends',
        desc: 'Add insights to your expenses'
      },
      {
        name: 'Profitability',
        desc: 'Get insight into your profit'
      },
      {
        name: ' VAT and Income tax projections',
        desc: 'Predictions of what your tax will be'
      },
      {
        name: '12 Months out Cash Flow Projection',
        desc: 'Predicts your cash balance 18 months in the future'
      },
      {
        name: 'Talk to your data, interactively, in simple terms',
        desc: ''
      },
    ],
    logomarkClassName: 'fill-cyan-500',
  },
]

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  activePeriod,
  logomarkClassName,
  featured = false,
}) {
  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5',
        featured ? 'order-first bg-gray-900 lg:order-none' : 'bg-white',
      )}
    >
      <h3
        className={clsx(
          'flex items-center text-sm font-semibold',
          featured ? 'text-white' : 'text-gray-900',
        )}
      >
        <Logomark className={clsx('h-6 w-6 flex-none', logomarkClassName)} />
        <span className="ml-4">{name}</span>
      </h3>
      <p
        className={clsx(
          'relative mt-5 flex text-3xl tracking-tight',
          featured ? 'text-white' : 'text-gray-900',
        )}
      >
        {price.Monthly === price.Annually ? (
          price.Monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === 'Annually'}
              className={clsx(
                'transition duration-300',
                activePeriod === 'Annually' &&
                  'pointer-events-none translate-x-6 select-none opacity-0',
              )}
            >
              {price.Monthly} {price.Monthly !== "Free" && <span className="text-sm">per month</span>}
            </span>
            <span
              aria-hidden={activePeriod === 'Monthly'}
              className={clsx(
                'absolute left-0 top-0 transition duration-300',
                activePeriod === 'Monthly' &&
                  'pointer-events-none -translate-x-6 select-none opacity-0',
              )}
            >
              {price.Annually}
            </span>
          </>
        )}
      </p>
      <p
        className={clsx(
          'mt-3 text-sm',
          featured ? 'text-gray-300' : 'text-gray-700',
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className={clsx(
            '-my-2 divide-y text-sm',
            featured
              ? 'divide-gray-800 text-gray-300'
              : 'divide-gray-200 text-gray-700',
          )}
        >
          {features.map((feature) => (
            <li key={feature} className="flex py-2">
              <CheckIcon
                className={clsx(
                  'h-6 w-6 flex-none',
                  featured ? 'text-white' : 'text-primary',
                )}
              />
              <div>
                <p className="ml-4">{feature.name} <br/>
                  <span className="mt-1 text-xs text-gray-500">{feature.desc}</span></p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Button
        href={button.href}
        color={featured ? 'cyan' : 'gray'}
        className="mt-6"
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {button.label}
      </Button>
    </section>
  )
}

export function Pricing() {
  let [activePeriod, setActivePeriod] = useState('Monthly')

  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <h2
            id="pricing-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            Flat pricing, no management fees.
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            Start today for free, or start with a boom with your Pro plan!
          </p>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="relative">
            {/*<RadioGroup*/}
            {/*  value={activePeriod}*/}
            {/*  onChange={setActivePeriod}*/}
            {/*  className="grid grid-cols-2"*/}
            {/*>*/}
            {/*  {['Monthly', 'Annually'].map((period) => (*/}
            {/*    <Radio*/}
            {/*      key={period}*/}
            {/*      value={period}*/}
            {/*      className={clsx(*/}
            {/*        'cursor-pointer border border-gray-300 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-sm text-gray-700 outline-2 outline-offset-2 transition-colors hover:border-gray-400',*/}
            {/*        period === 'Monthly'*/}
            {/*          ? 'rounded-l-lg'*/}
            {/*          : '-ml-px rounded-r-lg',*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      {period}*/}
            {/*    </Radio>*/}
            {/*  ))}*/}
            {/*</RadioGroup>*/}
            <div
              aria-hidden="true"
              className={clsx(
                'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg bg-primary transition-all duration-300',
                activePeriod === 'Monthly'
                  ? '[clip-path:inset(0_50%_0_0)]'
                  : '[clip-path:inset(0_0_0_calc(50%-1px))]',
              )}
            >
              {['Monthly', 'Annually'].map((period) => (
                <div
                  key={period}
                  className={clsx(
                    'py-2 text-center text-sm font-semibold text-white',
                    period === 'Annually' && '-ml-px',
                  )}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3">
          {plans.map((plan) => (
            <Plan key={plan.name} {...plan} activePeriod={activePeriod} />
          ))}
        </div>
      </Container>
    </section>
  )
}
