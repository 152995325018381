import Home from "./page";
import {Layout} from "./components/Layout";


function App() {
    return (
        <Layout>
            <Home/>
        </Layout>
    );
}

export default App;
