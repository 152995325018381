import { CallToAction } from './components/CallToAction'
import { Faqs } from './components/Faqs'
import { Hero } from './components/Hero'
import { Pricing } from './components/Pricing'
import { PrimaryFeatures } from './components/PrimaryFeatures'
import { Reviews } from './components/Reviews'
import { SecondaryFeatures } from './components/SecondaryFeatures'
import PanelContent from "./components/PanelContent";

export default function Home() {
  return (
    <>
      <Hero />
      <PrimaryFeatures />
        <PanelContent></PanelContent>
      <SecondaryFeatures />
      <CallToAction />
      <Pricing />
      <Faqs />
    </>
  )
}
